.detailed
	&__content
		.detailed__intro
			font-size 16px
		.detailed__title
			margin-bottom 3px
		.detailed__list
			margin-bottom 40px
			li
				padding 0
				margin-bottom 20px
				font-size 16px
				&::before
					display none
	&__carousel
		img
			display block
			width 100%
			border-radius 10px
	&__subheading
		text-transform uppercase
	&__col
		&:first-child
			margin-bottom 30px
	.table-wrapper
		overflow-x auto
		margin-bottom 40px
	&__table
		min-width 400px
		width 100%
		font-size 14px
		line-height 1.6

		td
			padding-bottom 15px
			vertical-align top
			&:first-child
				width 230px
				padding-right 30px
		p
			margin-bottom 15px
			&:last-child
				margin-bottom 0
@media screen and (min-width: 768px)
	.detailed
		&__layout
			display flex
			justify-content space-between
		&__col
			width calc(50% - 20px)

			&:first-child
				margin-bottom 0
		.swiper-navigation
			justify-content flex-start
		.table-wrapper
			overflow-x visible
@media screen and (min-width: 1300px)
	.detailed
		&__col
			width calc(50% - 30px)
			&--small-gutter
				width calc(50% - 20px)

