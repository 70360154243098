.callback
	&--big
		padding 60px 0
		background-image url(../images/callback-big.png)
		background-size cover
		background-position left center
		background-repeat no-repeat

	&__layout
		display flex
		flex-direction column-reverse
	&__column
		margin-bottom 45px
		&:first-child
			margin-bottom 0
	&__link
		display block
		img
			display block
			width 100%
			height auto
	&__heading
		margin-bottom 30px
		font-size 25px

@media screen and (max-width: 768px)
	.callback
		&--big
			.form__text
				padding 10px
				background-color rgba(255,255,255,0.6)
				border-radius 10px

			.form__checkbox-label
				padding-top 10px
				padding-bottom 10px
				padding-left 54px
				background-color rgba(255,255,255,0.6)
				border-radius 10px
				background-position left 10px center

@media screen and (min-width 768px)
	.callback
		&__layout
			display grid
			grid-column-gap 40px
			grid-template-columns 1fr 1fr
		&__column
			margin-bottom 0
@media screen and (min-width 1300px)
	.callback
		&--big
			padding 120px 0 450px 0
			margin-bottom -350px
		.heading--h2
			font-size 50px
		&__layout
			grid-template-columns 55% calc(45% - 60px)
			grid-column-gap 60px
