.breadcrumbs
	padding 30px 0

	&__list
		display flex
		align-items center
		width 100%
		overflow-x auto

	&__item
		position relative
		margin-right 30px
		white-space nowrap
		&::after
			content ''
			position absolute
			top 50%
			right -25px
			width 20px
			height 20px
			background-image url(../images/quote.svg)
			background-repeat no-repeat
			background-position center
			transform translateY(-50%)
		&:last-child
			&::after
				display none

	&__link
		text-decoration none
		color #EE5A21
		&:hover
			text-decoration underline
