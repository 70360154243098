.list
	li
		position relative
		padding-left 30px
		margin-bottom 10px
		&:last-child
			margin-bottom 0
		&::before
			content ''
			position absolute
			top 0.7em
			left 0
			width 6px
			height 6px
			background-color #C4C4C4
			border-radius 100%
			transform translateY(-50%)
	&--orange
		li::before
			width 10px
			height 10px
			background-color #EE5A21

@media screen and (min-width: 1300px)
	.list
		li
			margin-bottom 15px
