.search
	&__toggler
		display flex
		align-items center
		justify-content center
		width 40px
		height 40px
		border 2px solid #EE5A21
		border-radius 100%
		background-color transparent
		svg
			width 15px
			height 15px
			stroke #EE5A21
			fill none
			&:first-child
				display block
			&:last-child
				display none
		&.active
			svg
				fill #ee5a21
				&:first-child
					display none
				&:last-child
					display block

	&__block
		position absolute
		top 115px
		left 0
		width 100%
		padding 0 15px
		z-index 100
		display none
		&::before
			position absolute
			top 11px
			left 25px
			content url(../images/search-grey.svg)
		&.active
			display flex
	&__input
		display block
		height 60px
		margin-right 20px
		padding-left 75px
		flex-grow 1
		border 0
		border-radius 10px
		background-color #fff
		box-shadow 0 0 20px 2px rgba(19, 10, 54, 0.1)

	&__submit
		display flex
		align-items center
		justify-content center
		width 60px
		height 60px
		color #fff
		border 0
		border-radius 10px
		background-color #ee5a21
		&:hover
			background-color #F36D3A
		&:active
			background-color #D84E18
		svg
			display block
			width 25px
			height 25px
			fill currentColor
@media screen and (min-width: 768px)
	.search
		&__block
			top 150px
@media screen and (min-width 1300px)
	.search
		&__block
			top 130px
		&__toggler
			width 60px
			height 60px
		svg
			width 25px
			height 25px
