.prices
	&__heading
		margin-bottom 15px
	table
		width 100%
	td,
	th
		padding 15px 0
		font-size 16px
	th:last-child,
	td:last-child
		padding-left 45px
		white-space nowrap

	th
		text-align left

	tr
		&:not(first-child)
			border-bottom 1px solid #C4C4C4


@media screen and (min-width: 768px)
	.prices
		td,
		th
			padding 20px 0
			font-size 20px
@media screen and (min-width: 1300px)
	.prices
		td,
		th
			padding 20px 0
			font-size 20px
		th:last-child,
		td:last-child
			padding-left 90px
		.big
			font-size 25px
