.accordion
	&__item

		margin-bottom 40px
		border-bottom 1px solid #C4C4C4
		&:last-child
			margin-bottom 0
		&.js-accordion__item--open
			.accordion__expandable
				display block
			.accordion__toggler
				color #EE5A21
				&::before
					background-image url(../images/minus.svg)
	&__expandable
		display none
	&__toggler
		position relative
		margin-bottom 10px
		padding-bottom 30px
		padding-right 40px
		font-size 16px
		font-weight 700

		cursor pointer
		&::before
			content ''
			position absolute
			top 0
			right 0
			width 22px
			height 22px
			background-image url(../images/plus.svg)
			background-size 22px
			background-position center
			background-repeat no-repeat

	&__expandable
		position relative
		max-width 80%
		padding 20px
		margin 0 auto 40px auto
		background-color #fff
		border-radius 10px
		border 1px dashed #C4C4C4
		&::before
			content ''
			position absolute
			top -8px
			left 40px
			width 14px
			height 14px
			background-color #fff
			border-top 1px dashed #C4C4C4
			border-left 1px dashed #C4C4C4
			transform rotate(45deg)

@media screen and (min-width: 1300px)
	.accordion
		&__toggler
			font-size 20px
