.blog
	&__qr
		position absolute
		top 33px
		left 50px
	&__element
		position relative
		display block
		height 100%
		text-decoration none
		color inherit
		border-radius 10px
		box-shadow 0 0 30px rgba(0, 0, 0, 0.1)
		overflow hidden
		&:hover
			box-shadow 0 0 40px rgba(0, 0, 0, 0.15)
			.blog__text
				text-decoration none

	&__element-body
		padding 0 40px 40px 40px

	&__image
		display block
		width 100%
		height 200px
		margin-bottom 30px
		object-fit cover

	&__date
		display inline-flex
		padding 10px
		margin-bottom 30px
		font-size 13px
		font-weight 700
		line-height 1
		color #fff
		background-color #EE5A21

	&__text
		font-size 16px
		font-weight 700
		text-decoration underline

	&__footer
		padding-top 40px

@media screen and (min-width 768px)
	.blog
		&__items
			display flex
			flex-wrap wrap
			margin 0 -15px

		&__item
			width 50%
			padding 0 15px
			margin-bottom 30px

		&__text
			font-size 18px
@media screen and (min-width 1300px)
	.blog
		&__item
			width 33.333333%
