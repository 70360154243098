.card
	height 100%
	text-decoration none
	color inherit
	&--flip
		position relative
		&:hover
			z-index 1
			.card__backside
				visibility visible
				opacity 1
	&__backside
		position absolute
		top 0
		right 0
		left 0
		display flex
		min-height 100%
		flex-direction column
		align-items flex-start
		justify-content flex-start
		padding 45px 20px
		color #000
		background-color #fff
		visibility hidden
		opacity 0
		border-radius 10px
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.1)
		.card__name
			margin-bottom 20px

		.card__text
			margin-bottom 20px

		.button
			margin-top auto

	&--flip-big
		.card__backside
			z-index 1
			padding 15px

	&__links
		line-height 1.3
		li
			margin-bottom 10px
			&:last-child
				margin-bottom 0
		a
			font-size 15px

			color inherit
			&:hover
				color #EE5A21

	&__image
		position relative
		width 100%
		margin-bottom 20px
		border-radius 10px
		overflow hidden
		&::before
			content ''
			display block
			padding-bottom 100%

		img
			position absolute
			top 50%
			left 50%
			width 100%
			height 100%
			object-fit cover
			transform translate(-50%, -50%)

	&__name
		display block
		margin-bottom 10px
		font-size 18px
		font-weight 700
		color inherit 
		text-decoration none
		&:hover
			color #EE5A21

	&__small
		margin-bottom 20px
		font-size 14px

	&__price
		font-size 28px

	&__footer
		display flex
		align-items center
		justify-content space-between
a.card:hover
	color #EE5A21
@media screen and (min-width: 768px)
	.card
		&__name
			font-size 22px
		&__small
			font-size 16px
		&__price
			font-size 35px
		&__backside
			padding 90px 45px

@media screen and (min-width: 1300px)
	.card
		&--flip-big
			.card__backside
				flex-direction row
				right auto
				width auto
				min-width 630px
				padding 40px
				.card__image
					margin-right 30px

