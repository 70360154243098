.hero
	&__carousel
		& > .container
			position relative

	.swiper-slide
		height 100%
	.swiper-pagination
		position absolute
		left 50%
		bottom 20px
		display flex
		padding 0
		align-items center
		justify-content flex-start
		counter-reset pagination
		transform translateX(-50%)
		span
			position relative
			width 35px
			height 45px
			margin-right 20px
			border-radius 0
			color #CFCFCF
			background transparent
			opacity 1
			&.swiper-pagination-bullet-active
				color #EE5A21
			&::before
				content ''
				position absolute
				top 0
				left 0
				width 100%
				height 4px
				background-color currentColor
			&::after
				counter-increment pagination
				content '0' counter(pagination)
				position absolute
				bottom 0
				left 50%
				font-size 18px
				font-weight 700
				transform translateX(-50%)
	&__slide
		position relative
		height 100%
		padding 60px 0 100px 0
		font-size 14px
		color #fff
		background-size cover
		background-position right center
		background-repeat no-repeat
		.container
			position relative
			z-index 1
		&::before
			content ''
			position absolute
			top 0
			left 0
			width 100%
			height 100%
			background: linear-gradient(90deg, #B5390A 27.5%, rgba(238, 90, 33, 0) 89.58%);

		svg
			display block
			width 34px
			height 34px
			margin-right 20px
			fill none

	&__list
		li
			display flex
			align-items flex-start
			margin-bottom 40px

	&__images
		img
			display block
			width 100%
			max-width 400px
			height auto
			margin-bottom 20px
			border-radius 10px

			box-shadow 0 0 20px 1px rgba(0,0,0,0.3)

@media screen and (min-width: 800px)
	.hero
		&__list
			display flex
			align-items flex-start
			flex-wrap wrap
			li
				width calc(40% - 40px)
				margin-right 40px

		&__images
			display flex
			align-items flex-start
			padding-top 130px
			max-width 100%
			overflow hidden
			img
				width auto
				max-width none
			div
				margin-left 30px
				transform translateY(-110px)
				img
					margin-bottom 30px
					&:first-child
						max-height 260px
						transform translateX(-150px)
					&:last-child
						margin-bottom 0
@media screen and (min-width: 900px)
	.hero
		&__images
			max-width none
			overflow visible
		&__slide
			font-size inherit
			svg
				width 64px
				height 64px
@media screen and (min-width: 1150px)
	.hero
		.container
			display flex
			align-items flex-start
			justify-content space-between
		&__list
			display block
			li
				width auto
				margin 0
		&__images
			display flex
			padding-top 110px
			div
				margin-right -150px
				margin-bottom -110px
		&__slide
			padding 95px 0 130px 0
			background-size 100%
		&__list
			padding-top 120px
			li
				margin-bottom 50px

		.swiper-pagination
			left 15px
			bottom 80px
			transform none
			span
				&::after
					font-size 20px



