.heading
	font-weight 900
	text-transform uppercase
	&--invert
		color #fff

	&--h1
		font-size 25px
		margin-bottom 10px

	&--h2
		font-size 23px

	&--h3
		font-size 21px

	&--h4
		font-size 18px

@media screen and (min-width 768px)
	.heading
		&--h1
			margin-bottom 20px
			font-size 45px
		&--h2
			font-size 40px
		&--h3
			font-size 32px
		&--h4
			font-size 28px

@media screen and (min-width 1300px)
	.heading
		&--h1
			margin-bottom 30px
			font-size 75px
		&--h2
			font-size 65px
		&--h3
			font-size 55px
		&--h4
			font-size 45px
