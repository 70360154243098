.about

	&__image
		display block
		width 100%
		margin-bottom 30px
		border-radius 10px

	&__subtitle
		margin-bottom 30px
		font-size 20px
		font-weight 700
		line-height 1.1

	&__row
		margin-bottom 40px
		&--big
			margin-bottom 60px
		&--carousel
			position relative

	.about__push
		margin-bottom 30px

	&__intro
		font-size 25px
		font-weight 300

	.swiper-slide
		width auto
		&--complex
			display grid
			grid-template-rows 280px 280px
			grid-template-columns 3fr 2fr
			grid-row-gap 40px
			grid-column-gap 40px
		img
			display block
			width 100%
			height 100%
			object-fit cover
			border-radius 20px

	&__first
		grid-row 1/2
		grid-column 1/2

	&__second
		grid-row 1/2
		grid-column 2/3

	&__third
		grid-row 2/3
		grid-column 1/3
@media screen and (min-width: 768px)
	.about
		&__row
			display flex
			justify-content space-between
			&--carousel
				display block

		&__col
			width calc(50% - 20px)

		&__subtitle
			margin-bottom 40px
			font-size 25px
		&__row
			margin-bottom 60px
			&--big
				margin-bottom 90px
@media screen and (min-width: 1300px)
	.about
		background-image url(../images/about-bg.jpg)
		background-repeat no-repeat
		background-position left bottom
		background-size 50%
		&__intro
			font-size 45px
		&__row
			margin-bottom 80px
			&--big
				margin-bottom 120px
		&__col
			width calc(50% - 40px)

		.container
			position relative

		.swiper-navigation
			padding-top 0
			position absolute
			top 15px
			right 0

		.about__push
			margin-bottom 55px

		&__subtitle
			margin-top 40px
