.reviews
	&__heading
		margin-bottom 30px

	&__col
		margin-bottom 30px
		&:last-child
			margin-bottom 0

	&__image-link
		display inline-block
		line-height 1
		&:hover
			opacity 0.8
	&__image
		display block
		max-width 100%

	&__text
		margin-bottom 15px
		&--intro
			font-weight 700
		&:last-cihld
			margin-bottom 0
@media screen and (min-width: 400px)
	.reviews
		&__item
			display flex
		&__col
			width auto
			flex-grow 1
		&__image-link
			width 130px
			margin-right 20px
@media screen and (min-width: 768px)
	.reviews
		&__heading
			margin-bottom 50px
@media screen and (min-width: 1300px)
	.reviews
		.container
			position relative
		&__heading
			margin-bottom 100px
			font-size 50px
		.swiper-navigation
			padding-top 0
			position absolute
			top 0
			right 0

