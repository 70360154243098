.footer
	padding 30px 0
	color #fff
	background-color #353535
	svg
		fill currentColor

	&__menu
		max-width 670px
		margin-bottom 45px
		&::after
			content ''
			display block
			clear both

	&__menu-item
		margin-bottom 15px

	&__menu-link
		font-size 16px
		font-weight 700
		text-decoration none
		color inherit
		&:hover
			color #EE5A21

	&__submenu
		padding 15px 0 0 15px
		.footer__menu-link
			font-size 14px
			font-weight normal

	&__phones
		display flex
		flex-direction column
		margin-bottom 30px

	&__phone
		display inline-flex
		align-items center
		margin-bottom 20px
		font-size 16px
		font-weight 700
		text-decoration none
		color #fff
		&:last-child
			margin-bottom 0
		svg
			width 20px
			height 20px
			margin-right 20px

	&__license
		display inline-block
		margin-bottom 30px
		font-size 13px
		font-weight 300
		color #fff
		&:hover
			color #EE5A21

	&__address
		margin-bottom 30px
		font-size 20px
		font-weight 300

	&__time
		display flex
		align-items center
		margin-bottom 30px
		svg
			width 20px
			height 20px
			margin-right 20px
	&__social
		display flex
		align-items center
		margin-bottom 30px

	&__social-link
		display block
		margin-right 30px
		color #fff
		&:hover
			color #EE5A21
		&:last-child
			margin-right 0
		svg
			width 30px
			height 30px
	&__copy
		font-size 13px
		color #AFAFAF


@media screen and (min-width: 670px)
	.footer
		&__menu-item
			width 50%
			float: left
			margin-bottom 27.5px
			line-height 1.15
			&:last-child
				margin-bottom 0
			&:first-child
				margin-bottom 0
				padding-right 40px
		&__menu-link
			font-size 20px

		&__submenu
			padding-top 30px
			padding-left 0
			.footer__menu-item
				width 100%
				float none
				margin-bottom 13px
				padding 0
				line-height 1.4
				&:first-child
					margin-bottom 13px
				&:last-child
					margin-bottom 0
			.footer__menu-link
				font-size 14px
				font-weight normal
		&__address
			max-width 50%
@media screen and (min-width: 1024px)
	.footer
		padding 60px 0
		&__layout
			display flex
			justify-content space-between
		&__menu
			margin-bottom 0
@media screen and (min-width: 1300px)
	.footer
		padding 80px 0
		&__layout
			justify-content flex-start

		&__info
			position relative
			display flex
			flex-direction column
			align-items flex-start
			justify-content flex-start
			padding-left 100px
			flex-grow 1
		&__phone
			font-size 22px
		&__time
			font-size 22px
		&__license
			font-size 15px
		&__address
			max-width none
			font-size 25px
			br
				display block

		&__social
			position absolute
			top 0
			right 0
		&__copy
			margin-top auto
