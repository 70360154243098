.page-links

	&__link
		display block
		width 100%
		max-width 250px
		margin-bottom: 20px
		margin-right: 20px
		color inherit
		text-decoration none
		border-bottom 4px solid transparent
		&--active
			color #EE5A21
			border-color #EE5A21
@media screen and (min-width: 1300px)
	.page-links
			display flex
			align-items flex-start
			&__controls
				align-items flex-start
				width 200px
				margin-right 40px
			&__content
				width calc(100% - 240px)		
