.wrapper
	&--background
		padding-bottom 90px
		background-color #F9F9F9
@media screen and (min-width: 768px)
	.wrapper
		&--background
			padding-bottom 120px

@media screen and (min-width: 1300px)
	.wrapper
		&--background
			padding-bottom 140px
