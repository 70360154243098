@font-face
	font-family 'Raleway'
	src url('../fonts/Raleway-Bold.woff2') format('woff2'),
		url('../fonts/Raleway-Bold.woff') format('woff')
	font-weight bold
	font-style normal
	font-display swap


@font-face
	font-family 'Raleway'
	src url('../fonts/Raleway-Regular.woff2') format('woff2'),
		url('../fonts/Raleway-Regular.woff') format('woff')
	font-weight normal
	font-style normal
	font-display swap


@font-face
	font-family 'Raleway'
	src url('../fonts/Raleway-Light.woff2') format('woff2'),
		url('../fonts/Raleway-Light.woff') format('woff')
	font-weight 300
	font-style normal
	font-display swap


@font-face
	font-family 'Raleway'
	src url('../fonts/Raleway-Black.woff2') format('woff2'),
		url('../fonts/Raleway-Black.woff') format('woff')
	font-weight 900
	font-style normal
	font-display swap


