.main
	padding-top 120px
	padding-bottom 90px
	&--main
		padding-bottom 0
	&__section
		margin-bottom 60px
@media screen and (min-width: 768px)
	.main
		padding-bottom 120px
		padding-top 145px
		&--main
			padding-bottom 0
		&__section
			margin-bottom 90px
@media screen and (min-width: 1024px)
	.main
		padding-top 123px
@media screen and (min-width: 1300px)
	.main
		padding-bottom 140px
		&--main
			padding-bottom 0
		&__section
			margin-bottom 120px
@media screen and (min-width: 1420px)
	.main
		padding-top 126px
