.content
	h1
		font-weight 900
		text-transform uppercase
		font-size 25px
		margin-bottom 10px
	h2
		margin-bottom 20px
		font-size 23px
		font-weight 900
		text-transform uppercase
	p
		margin-bottom 10px
		&:last-child
			margin-bottom 0
	ul 
		padding-left 20px
		margin-bottom: 30px
		li
			position relative
			margin-bottom 15px
			&:last-child
				margin-bottom 0
			&::before
				position absolute
				content ''
				top 0.5em
				left -20px
				width 8px
				height 8px
				background-color #EE5A21
				border-radius 100%

	ol 
		margin-bottom: 30px
		padding-left 20px
		counter-reset: ol
		li
			position relative
			margin-bottom 15px
			&:last-child
				margin-bottom 0
			&::before
				position absolute
				content counter(ol)
				counter-increment ol
				top 0
				left -20px
				font-weight: bold	

@media screen and (min-width 768px)
	.content
		h1
			margin-bottom 20px
			font-size 45px
		h2
			font-size 40px
		h3
			font-size 32px
		h4
			font-size 28px

@media screen and (min-width: 1300px)
	.content
		p
			margin-bottom 30px
		h1
			margin-bottom 30px
			font-size 75px
		h2
			margin-bottom 40px
			font-size 65px
		h3
			font-size 55px
		h4
			font-size 45px

