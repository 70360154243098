.popup
	display none
	&--shown
		display block
	&__heading
		margin-bottom 35px
	&__layout
		position fixed
		top 0
		left 0
		z-index: 105
		display flex
		align-items flex-start
		width 100%
		height 100%
		padding 30px 15px
		background-color rgba(0, 0, 0, 0.75)
		overflow auto
	&__body
		position relative
		width 100%
		padding 40px 35px
		margin auto
		background-color #fff
	&__close
		position absolute
		top 20px
		right 20px
		display flex
		align-items center
		justify-content center
		width 30px
		height 30px
		background-color #fff
		border none
		svg
			width 22px
			height 22px
			stroke-width 2px
			stroke currentColor
	&#callback
		.popup__body
			max-width 975px

	&#thanks
		.popup__body
			max-width 760px
			padding 100px 30px
		.heading--h1
			margin-bottom 15px
			line-height 1.1
@media screen and (min-width: 768px)
	.popup
		&__body
			padding 80px 70px
		&#thanks
			.popup__body
				padding 200px 60px
		&#order
			.popup__body
				max-width 1280px
			.form
				display flex
				align-items center
				justify-content space-between
				flex-wrap wrap
			.form__input-group
				width calc(50% - 20px)
				margin-bottom 40px
			.form__policy
				margin-bottom 0
@media screen and (min-width: 1300px)
	.popup
		&__body
			padding 140px 170px
		&#order
			.form__input-group:nth-child(-n + 3)
				width calc(33.333333% - 20px)
			.form__input-group
				width 100%
