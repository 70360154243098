.button
	display flex
	padding 15px 30px
	text-decoration none
	color #fff
	background-color #EE5A21
	border none
	border-radius 5px

	&:hover
		background-color #F36D3A
	&:active
		background-color #D84E18
	&--big
		padding 22px 41px
	&--bold
		font-weight 700
	&--centered
		align-items center
		justify-content center
	&--invert
		color #EE5A21
		background-color transparent
		border 1px solid #EE5A21
		&:hover
			color #fff
			background-color #EE5A21
		&:active
			border-color #D84E18
			background-color #D84E18
