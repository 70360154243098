.section
	&--background
		padding 60px 0
		background-color #fff
	&__heading
		text-align center

@media screen and (min-width: 768px)
	.section
		&--background
			padding 100px 0

@media screen and (min-width: 1300px)
	.section
		&--background
			padding 140px 0
