.products
	&__layout
		display flex
		flex-direction column-reverse
	&__heading
		margin-bottom 30px
	&__list
		margin-bottom 30px
		line-height 1.4
	&__conclusion
		font-weight 700
	&__col
		margin-bottom 30px
		&:first-child
			margin-bottom 0
	&__image
		display block
		max-width 100%
		border-radius 10px
	&__row
		margin-bottom 30px
		&:last-child
			margin-bottom 0
	.products__col
		margin-bottom 30px
		&:last-child
			margin-bottom 0
	&__items
		display flex
		flex-wrap wrap
		margin 0 -15px 60px -15px

	&__item
		width 100%
		max-width 405px
		padding 0 15px
		margin-bottom 30px
@media screen and (min-width: 500px)
	.products
		&__item
			width 50%
@media screen and (min-width: 700px)
	.products
		&__layout
			flex-direction row
			justify-content space-between
		&__col
			width 47.5%
			margin-bottom 0
		&__row
			.products__col
				width 100%
@media screen and (min-width: 800px)
	.products
		&__item
			width 33.33333%
@media screen and (min-width: 1024px)
	.products
		&__row
			display flex
			align-items flex-start
			justify-content space-between
			.products__col
				width 47.5%
		&__items
			margin-bottom 120px

@media screen and (min-width: 1300px)
	.products
		overflow hidden
		&--main
			.heading--h2
				color #fff
		&__item
			width 25%
			max-width none
			&:nth-child(4n + 4)
				.card__backside
					right 0
					left auto
					flex-direction row-reverse
		&--main
			.products__item
				width 33.3333%
		&__col
			&--big
				width 66.66666%
				padding-right 60px
				font-size 16px
		&__heading
			margin-bottom 65px
		&__list
			margin-bottom 60px
			font-size 18px
			li
				margin-bottom 30px
		&__conclusion
			font-size 18px
			line-height 1.65

@media screen and (min-width: 1400px)
	.products
		&__items
			margin 0 -20px 120px -20px
		&__item
			padding 0 20px
