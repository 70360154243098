.portfolio
	&__item
		margin-bottom 30px

	&__link
		display block
		width 100%
		height 100%

		img
			display block
			width 100%
			height 100%
			border-radius 10px
			object-fit cover

@media screen and (min-width 768px)
	.portfolio

		&__items
			display flex
			flex-wrap wrap
			margin 0 -15px

		&__item
			width 33.33333%
			margin-bottom 30px
			padding 0 15px
			&--video
				width 50%
