.faq
	padding 30px 0
	background-color #F6F6F6
	box-shadow inset 0 10px 15px rgba(0, 0, 0, 0.1);
	&__heading
		margin-bottom 30px
		text-align center

@media screen and (min-width: 768px)
	.faq
		padding 100px 0

@media screen and (min-width: 1300px)
	.faq
		padding 140px 0
		&__heading
			margin-bottom 65px
