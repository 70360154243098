.menu
	position absolute
	top 105px
	right 0
	width 100%
	overflow-y auto
	padding 30px 15px 0 15px
	background-color #fff
	display none
	box-shadow 0 5px 5px rgba(0,0,0,0.15)
	&.active
		display block

	&__item
		margin-bottom 10px

	&__link
		font-size 16px
		font-weight 700
		text-decoration none
		color inherit
		&:hover
			color #EE5A21

	&__dropdown
		min-width 450px
		max-height: calc(100vh - 150px)
		padding-left 15px
		padding-top 10px
		.menu__link
			font-weight 400
			font-size 14px
@media screen and (min-width: 768px)
	.menu
		top 130px
@media screen and (min-width 1024px)
	.menu
		position static
		display block
		padding 0
		box-shadow none
		height auto

		&__list
			display flex
			justify-content space-between

		&__dropdown-wrapper
			position absolute
			display none
			padding-top 15px

		&__dropdown
			padding 40px
			background-color #fff
			box-shadow 0 0 20px 2px rgba(0, 0, 0, 0.15)

		&__item
			&--dropdown
				padding-right 25px
				background-image url(../images/dropdown.svg)
				background-repeat no-repeat
				background-position right center
				&:hover
					.menu__dropdown-wrapper
						display block
@media screen and (min-width 1300px)
	.menu
		&__list
			justify-content flex-start
		&__item
			margin-right 30px
