.licenses

	&__item
		display block
		color inherit
		text-decoration nonde
		img
			display block
			width 100%
@media screen and (min-width: 1300px)
	.licenses
		.container
			position relative
		&__heading
			margin-bottom 80px
			line-height 1.1
		.swiper-navigation
			padding-top 0
			position absolute
			top 75px
			right 0
