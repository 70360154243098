.header
	position fixed
	top 0
	left 0
	width 100%
	background-color #fff
	display flex
	align-items center
	justify-content space-between
	padding 65px 15px 15px 15px
	z-index 100
	&__logo
		display block
		width 150px
		padding: 10px 20px
		background-color #353535
		img
			display block
			width 100%

	&__menu-button
		display flex
		align-items center
		justify-content center
		width 30px
		height 30px
		border 0
		background-color transparent
		color #EE5A21
		svg
			width 30px
			height 30px
			fill currentColor
		svg:first-child
			display block
		svg:last-child
			display none
		&.active
			svg:first-child
				display none
			svg:last-child
				display block
	&__layout
		position absolute
		top 15px
		left 0
		right 0
		width 100%
		padding 0 15px
		z-index 100

	&__top
		display flex
		align-items center
		justify-content space-between
		.button
			padding 8px 12px
			font-size 12px

	&__phone
		font-weight 700
		text-decoration none
		color inherit

	&__slogan
		display none
	&__time
		display none
	&__mail
		display none

@media screen and (min-width 768px)
	.header
		padding 90px 15px 15px 15px
		&__layout
			top 15px
			left 0
			width 100%
			padding 0 15px
		&__top
			font-size 14px
			.button
				padding 15px 30px
				font-size inherit
		&__time
			display block
		&__mail
			display block
		&__time
			&::before
				content url(../images/clock.svg)
				display inline-block
				margin-right 10px
				vertical-align middle
				line-height 1

		&__mail
			color #ee5a21
			&::before
				content url(../images/envelop.svg)
				display inline-block
				margin-right 10px
				vertical-align middle
				line-height 1
			&:hover
				text-decoration none
@media screen and (min-width 1024px)
	.header
		padding 15px 15px 15px 155px
		&__layout
			position static
		&__logo
			position absolute
			left 0
			top 0
			bottom 0
			display flex
			align-items center
			justify-content center
				
		&__top
			margin-bottom 15px
		&__menu-button
			display none
@media screen and (min-width: 1366px)
	.header
		&__slogan
			display block
			max-width 215px
			font-size 14px
			margin-right auto	
		&__time,
		&__mail
			margin-right 45px

		&__phone
			margin-right 35px
			font-size 20px

		&__modal
			margin-right 30px			
@media screen and (min-width 1420px)
	.header
		padding 15px 15px 15px 220px

		&__layout
			width 100%
			max-width 1300px
			margin 0 auto
			padding 0 15px
			justify-content flex-end

		&__logo
			width 200px
			padding 15px 30px

		

@media screen and (min-width 1700px)
	.header
		padding 15px 0
		&__search
			position absolute
			top 50%
			right 25px
			transform translateY(-50%)
@media screen and (min-width 1920px)
	.header
		&__logo
			width 270px
		&__modal
			margin-right 0
