.tabs
	&__tab
		display none
		&--active
			display block

	&__controls
		display flex
		align-items center
		flex-wrap wrap
		margin-bottom 10px

	&__label
		margin-right 20px
		margin-bottom 20px
		cursor pointer
		&:last-of-type
			margin-right 0

	&--buttons

		.tabs__label
			display flex
			align-items center
			justify-content center
			width 100%
			min-height 70px
			padding 15px 20px
			margin-right 10px
			font-size 13px
			font-weight 900
			line-height 1.1
			text-align center
			text-transform uppercase
			color #7E7E7E
			border 1px solid #7E7E7E
			border-radius 5px
		input:checked + label
			color #fff
			background-color #EE5A21
			border-color #EE5A21

@media screen and (min-width: 768px)
	.tabs
		&--buttons

			.tabs__label
				width auto
				text-align left
				justify-content flex-start
@media screen and (min-width: 1300px)
	.tabs
		&--links
			display flex
			align-items flex-start
			.tabs__controls
				align-items flex-start
				width 200px
				margin-right 40px
			.tabs__content
				width calc(100% - 240px)

