.form

	&__input-group
		margin-bottom 20px
		background-color #fff
		background-position 15px center
		background-repeat no-repeat
		background-size 26px
		&:last-child
			margin-bottom 0
		&--name
			background-image url(../images/form-user.svg)
		&--phone
			background-image url(../images/form-phone.svg)
		&--phone-grey
			background-image url(../images/form-phone-grey.svg)
		&--email
			background-image url(../images/form-mail.svg)
		&--message
			background-image url(../images/form-chat.svg)

	&__input
		display flex
		align-items center
		height 100%
		width 100%
		padding 15px 15px 15px 50px
		font-weight 700
		background-color transparent
		border 1px solid #7C7C7C
		border-radius 5px
		&:focus
			border-color #4B7ECB

	&__checkbox-label
		display block
		padding-left 44px
		min-width 24px
		background-image url(../images/unchecked.svg)
		background-position left center
		background-size 24px
		background-repeat no-repeat
		cursor pointer

	&__checkbox:checked + label
		background-image url(../images/checked.svg)

	&__text
		margin-bottom 30px
	&__policy
		margin-bottom 30px
@media screen and (min-width: 768px)
	.form
		&__input-group
			background-position 25px center
		&__input
			padding-top 25px
			padding-bottom 25px
			padding-left 60px
		&__policy
			margin-bottom 50px
