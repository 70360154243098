.services
	&--main
		padding 40px 0
		color #fff
		background-color #353535
		.heading
			color #fff
		.services__items
			margin 0 -15px 0 -15px

	&__heading
		margin-bottom 30px
		color #000

	&__items
		display flex
		flex-wrap wrap
		margin 0 -15px 60px -15px

	&__item
		width 100%
		max-width 405px
		padding 0 15px
		margin-bottom 30px

	&__footer
		display flex

	&__text
		margin-bottom 30px
		&--intro
			margin-bottom 40px

	&__content-image
		display block
		max-width 100%
		border-radius 10px

	&__col
		&:first-child
			margin-bottom 30px
@media screen and (min-width: 500px)
	.services
		&__item
			width 50%
@media screen and (min-width: 800px)
	.services
		&__item
			width 33.33333%
@media screen and (min-width: 1024px)
	.services
		&__layout
			display flex
			justify-content space-between
		&__col
			width calc(50% - 20px)
			&:first-child
				margin-bottom 0
		&__text
			margin-bottom 40px
			&--intro
				margin-bottom 60px
@media screen and (min-width: 1300px)
	.services
		&--main
			padding 0
			background-color transparent
			.services__item
				width 33.3333%
			.heading
				color #000
		&__item
			width 25%
			max-width none
		&__heading
			margin-bottom 60px
			&--right
				text-align right
		&__col
			width calc(50% - 40px)
		&__list
			max-width 550px
@media screen and (min-width: 1400px)
	.services
		&--main
			.services__items
				margin 0 -20px 30px -20px
		&__items
			margin 0 -20px 120px -20px
		&__item
			padding 0 20px

